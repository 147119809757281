import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';

const key = process.env.REACT_APP_APP_INSIGHTS_KEY;

const reactPlugin = new ReactPlugin();

const appInsights = new ApplicationInsights({
  config: {
    instrumentationKey: key,
    extensions: [reactPlugin],
    extensionConfig: {
      [reactPlugin.identifier]: {
        // Configurações específicas para React Native (opcional)
      },
    },
  },
});

appInsights.loadAppInsights();

appInsights.addTelemetryInitializer((envelope) => {
  if (envelope.data && envelope.baseData) {
    envelope.baseData.properties = {
      ...envelope.baseData.properties, 
      source: 'web', 
    };
  }
});

export const trackEvent = appInsights.trackEvent.bind(appInsights);
export const trackException = appInsights.trackException.bind(appInsights);
export const trackTrace = appInsights.trackTrace.bind(appInsights);
export const trackMetric = appInsights.trackMetric.bind(appInsights);

export default appInsights;