import { v4 as uuidv4 } from 'uuid';

export const addTokenInRequest = (payload: any) => {
  if (typeof payload === 'object') {
    const validation_token = uuidv4();

    return { ...payload, validation_token: validation_token + 'web' };
  }

  return payload;
};
