import { AxiosResponse } from 'axios';
import HttpOrdersService from '../../config/HttpOrders';
import { addTokenInRequest } from '../../utils/requestToken';
import {
  GetOrdersReadFilter,
  LotNumberValidateResponse,
  PostDeliveredToCarrierProps,
  PostMultipleOrderRead,
  PostOrderReadProps,
  PutOrderReadLotNumberProps,
  type OrderReadLotNumberValidateResponse,
  type PostOrderConfirmationProps,
  type ToDropResponse,
} from './orders.types';
import useAuth0Credentials from '../../hooks/useAuth0Credentials';
import { trackEvent, trackException } from '../appInsights';
import { OrderReadAction } from '../../enum';

export class OrderService {
  api: HttpOrdersService;

  constructor() {
    this.api = new HttpOrdersService();
  }

  getOrders = (filter: any, page_number: number, page_size: number) => {
    return this.api.post(
      `v1/orders/search?page_number=${page_number}&page_size=${page_size}`,
      filter
    );
  };

  getOrder = (id: string) => {
    return this.api.get(`v1/orders/${id}`);
  };

  getOrderQuote = (id: string) => {
    return this.api.get(`v1/orders/${id}/quote`);
  };

  getTotalizerOrders = (id: string) => {
    return this.api.get(
      `v1/orders/business_unit/counters?business_unit_id=${id}`
    );
  };

  getLabelHTML = (trackingNumber: string) => {
    return this.api.get(`v1/orders/${trackingNumber}/label_raw_html`);
  };

  getTransportOrders = (code: string) => {
    return this.api.get(`v1/orders?barcode=${code}`);
  };

  postOrdersReceive = async (payload: any, userId: string) => {
    trackEvent({ name: 'Finalizando recebimento', properties: { payload, userId } });

    const response = await this.api.post(
      'v1/orders/actions/dropped_by_client',
      addTokenInRequest(payload)
    ).catch((error: any) => {
      trackException(
        { exception: error },
        { error_response: error?.response || 'Erro não identificado', userId },
      );
      throw error
    });

    return response
  };

  getOrdersFinalConsumer = () => {
    return this.api.get('v1/orders/recipient/counters');
  };

  getListCarrier = () => {
    return this.api.get('v1/orders/carrier_count');
  };

  getOrdersCarrier = (id: string, payload: any) => {
    return this.api.get(`v1/orders/carrier/${id}`, payload);
  };

  getOrdersRecipient = (document: string) => {
    return this.api.get(`v1/orders/recipient/${document}`);
  };

  postDeliveredRecipient = (payload: any) => {
    return this.api.post(`v1/orders/actions/delivered_to_recipient`, payload);
  };

  postDeliveredClient = (id: string, payload: any) => {
    return this.api.post(
      `v1/orders/${id}/actions/delivered_to_client`,
      payload
    );
  };

  getOrdersRead = (
    filter: GetOrdersReadFilter,
    page_number: number,
    page_size: number,
    col?: string,
    order?: string
  ) => {
    const url = `v1/orders/business_unit/order_read/search?page_number=${page_number}&page_size=${page_size}`;
    if (col) {
      return this.api.post(
        `${url}&sort_column=${col}&sort_order=${order}`,
        filter
      );
    }

    return this.api.post(url, filter);
  };

  getOrderRead = (payload: any): Promise<AxiosResponse<any[]>> => {
    return this.api.get(`v1/orders/business_unit/order_read`, {
      ...payload,
      is_labeless: payload.is_labeless || false,
    });
  };

  postOrderRead = async (
    payload: PostOrderReadProps, userId: string
  ): Promise<AxiosResponse<string>> => {   
    trackEvent({
      name: `Leitura de QRCode ${payload.read_action === OrderReadAction.Pickup ? "Pickup" : "Lote"} Request`,
      properties: {
        payload,
        userId
      }
    })

    const response = await this.api.post(`v1/orders/business_unit/order_read`, payload)
    .catch((error: any) => {
      trackException(
        { exception: error },
        { error_response: error?.response || 'Erro não identificado', userId },
      );

      throw error
    });

    return response
  };

  postOrderReadMultiple = (payload: PostMultipleOrderRead) => {
    return this.api.post(
      `v1/orders/business_unit/order_read/multiple`,
      payload
    );
  };

  deleteOrderRead = async (orderId: string, payload: any, userId: string): Promise<any> => {
    trackEvent({ 
      name: `Removendo item order read ${payload.order_read_action === OrderReadAction.Pickup ? "Pickup" : "Lote"}`, 
      properties: { payload, userId } 
    });

    const response = await this.api.delete(
      `v1/orders/business_unit/order_read/${orderId}`,
      payload
    ).catch((error: any) => {
      trackException(
        { exception: error },
        { error_response: error?.response || 'Erro não identificado', userId },
      );
      throw error
    });

    return response
  };

  postOrdersCarrier = (payload: any) => {
    return this.api.post(`v1/orders/carrier`, payload);
  };

  postDeliveredToCarrier = async (payload: PostDeliveredToCarrierProps, userId: string) => {  
    trackEvent({
      name: "Finalizando entrega",
      properties: { payload, userId }
    })

    const response = await this.api.post(`v1/orders/actions/pickup/carrier`, payload)
    .catch((error: any) => {
      trackException(
        { exception: error },
        { error_response: error?.response || 'Erro não identificado', userId },
      );
      throw error
    });

    return response
  };

  getOrdersToDrop = (barcode: string): Promise<AxiosResponse<ToDropResponse[]>> => {
    return this.api.get(`v1/orders/${barcode}/to_drop`);
  };

  getOrdersToPickup = (barcode: string) => {
    return this.api.get(`v1/orders/${barcode}/to_pickup`);
  };

  getPlatformOrdersFetcher = (platform: number) => {
    return this.api.get(`v1/platform-orders-fetcher/platform/${platform}`);
  };

  deletePlatformOrdersFetcher = (id: string) => {
    return this.api.delete(`v1/platform-orders-fetcher/${id}`);
  };

  postPlatformOrdersFetcher = (payload: any) => {
    return this.api.post(`v1/platform-orders-fetcher/fetch`, payload);
  };

  postPlatformOrdersFetcherDrop = (payload: any) => {
    return this.api.post(`v1/platform-orders-fetcher/drop`, payload);
  };

  getPlatformOrdersFetcheCountPickup = (platform: number) => {
    return this.api.get(
      `v1/platform-orders-fetcher/platform/${platform}/count_pickup`
    );
  };

  getPlatformOrdersFetcherPickup = (platform: number) => {
    return this.api.get(
      `v1/platform-orders-fetcher/platform/${platform}/list_pickup`
    );
  };

  getPlatformOrdersFetcherShopee = (platform: number, toNumber: string) => {
    return this.api.get(
      `v1/platform-orders-fetcher/platform/${platform}/list_pickup/${toNumber}`
    );
  };

  postPlatformOrdersFetcherPickup = (payload: any) => {
    return this.api.post(`v1/platform-orders-fetcher/set_pickup`, payload);
  };

  postPlatformOrdersFetcherShopee = (toNumber: string, payload: any) => {
    return this.api.post(
      `v1/platform-orders-fetcher/transport_order/${toNumber}/set_pickup`,
      payload
    );
  };

  deletePlatformOrdersFetcherPickup = (id: string) => {
    return this.api.delete(`v1/platform-orders-fetcher/pickup/${id}`);
  };

  deletePlatformOrdersFetcherShopee = (id: string, toNumber: string) => {
    return this.api.delete(
      `v1/platform-orders-fetcher/transport_order/${toNumber}/order/${id}`
    );
  };

  postPlatformOrdersFetcherPickupComplete = (payload: any) => {
    return this.api.post(`v1/platform-orders-fetcher/complete_pickup`, payload);
  };

  postPlatformOrdersFetcherPickupCompleteShopee = (
    toID: string,
    payload: any
  ) => {
    return this.api.post(
      `v1/platform-orders-fetcher/transport_order/${toID}/complete_pickup`,
      payload
    );
  };

  getTO = (filter: any, page_number: number, page_size: number) => {
    return this.api.post(
      `v1/platform-orders-fetcher/transport_order/search?page_number=${page_number}&page_size=${page_size}`,
      filter
    );
  };

  createTO = (payload: any) => {
    return this.api.post(`v1/platform-orders-fetcher/transport_order`, payload);
  };

  getValidateProcessingDrop = (barcode: string, carrierId: string) => {
    return this.api.get(`v1/orders/${barcode}/validate_processing_drop`, {
      carrier_id: carrierId,
    });
  };

  validateLabel = (labelNumber: string, orderId: string) => {
    return this.api.get(
      `/v1/orders/labeless/${labelNumber}/validation/?order_id=${orderId}`
    );
  };

  updateOrderReadLotNumber = async (payload: PutOrderReadLotNumberProps, userId: string) => {
    trackEvent({ name: 'Atualizar lote order read', properties: { payload, userId } });  

    const response = await this.api.patch(
      `v1/orders/business_unit/order_read/lot_number`,
      payload
    )
    .catch((error: any) => {
      trackException(
        { exception: error },
        { error_response: error?.response || 'Erro não identificado', userId },
      );
      throw error
    });
    
    return response
  };

  getOrderReadLotNumberValidate = async (
    payload: string, userId: string
  ): Promise<AxiosResponse<LotNumberValidateResponse>> => {
    trackEvent({ name: 'Validar numero de lote', properties: { payload, userId } });

    const response = await this.api.get(`v1/orders/lot_number/${payload}/validate`)
    .catch((error:any) => {
        trackException(
          { exception: error },
          { error_response: error?.response || 'Erro não identificado', userId },
        );
        throw error
      });

    return response
  };

  postOrderHasConfirmation = (
    payload: { order_ids: string[] }
  ): Promise<AxiosResponse<OrderReadLotNumberValidateResponse>> => {
    return this.api.post(`v1/orders/has_drop_confirmation`, payload);
  }

  postOrderDropConfirmation = (
    payload: PostOrderConfirmationProps
  ): Promise<AxiosResponse<string[]>> => {
    return this.api.post(`v1/orders/drop_confirmation`, payload);
  }
}
